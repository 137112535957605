<template>
    <div class="u-rel c-attribute">
        <template v-if="!isCustomized || attributeName !== 'turnaround'">
            <div v-if="!isCustomValue" class="d-flex align-items-end">
                <mercur-select class="flex-grow-1" v-model="value" :disabled="isNotSelectable && !isCustomized || disabled" ref="attributeSelect" @selected="selectAttribute($event)">
                    <template #label>{{ attributeName }}</template>
                    <template v-if="isTurnaround">
                        <option v-for="(value, key) in attribute" :key="key" :value="value.turnaround">
                            {{ value.targetDeliveryDate }} (days - {{ value.turnaround }})
                            <strong>- price: {{ value.price.toFixed(2) }}</strong>
                        </option>
                    </template>
                    <template v-else>
                        <option v-for="(value, index) in attribute" :value="value" :key="index">{{ value }}</option>
                    </template>
                </mercur-select>
                <div v-if="isCustomizable && attributeName !== 'turnaround'">
                    <mercur-tooltip class="mb-3">
                        <template #label>Add custom value</template>
                        <mercur-button :disabled="isNotSelectable && !isCustomized" class="btn btn-icon btn-icon-square" @click.native="isCustomValue = true">
                            <i class="fas fa-plus"></i>
                        </mercur-button>
                    </mercur-tooltip>
                </div>
            </div>
            <div class="d-flex align-items-end" v-else>
                <mercur-input class="flex-grow-1" @keyup.enter="setCustomValue" v-model="customValue" :disabled="isNotSelectable" type="text">
                    {{ attributeName }} - custom value
                </mercur-input>
                <div>
                    <mercur-tooltip class="mb-3">
                        <template #label>Add preset value</template>
                        <mercur-button class="btn btn-icon btn-icon-square" @click.native="isCustomValue = false">
                            <i class="fas fa-trash"></i>
                        </mercur-button>
                    </mercur-tooltip>
                </div>
            </div>
            <mercur-spinner class="loading-spinner" v-if="loading" size="1"></mercur-spinner>
        </template>
    </div>
</template>

<script>
import AttributeLogic from './AttributeLogic'

export default {
    name: 'ProductAttribute',
    mixins: [AttributeLogic],
}
</script>

<style lang="scss" scoped>
    .loading-spinner {
        position: absolute;
        right: 4px;
        background-color: white;
        top: 30px;
    }
</style>
