<script>
import { mapState } from 'vuex'

export default {
    name: 'DialogLogicMixin',
    data () {
        return {
            isOpen: false,
            form: {},
        }
    },
    props: {
        fields: {
            default: () => {},
        },
        isEditVats: {
            default: true,
        },
    },
    watch: {
        fields: {
            handler (value) {
                if (value && Object.keys(value).length) {
                    this.isOpen = true
                    this.$set(this, 'form', JSON.parse(JSON.stringify(value)))
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapState('shop', ['currencyConversionRates']),
    },
    methods: {
        save () {
            this.$v.$touch()

            if (this.$v.$invalid || this.$v.$error) {
                return
            }

            const currency = this.fields.currency

            if (!currency) {
                this.$root.$emit('notification:global', {
                    message: 'Product payload does not have currency code',
                    type: 'error',
                })
                return
            }

            if (!this.currencyConversionRates || !this.currencyConversionRates[currency]) {
                this.$root.$emit('notification:global', {
                    message: 'Currency conversion rates are not defined. Try refreshing the page.',
                    type: 'error',
                })
                return
            }

            let item = this.form.product
            if (!item) {
                item = this.form
            }

            if (!item.prices.currencySpecificPrices[currency]) {
                this.$root.$emit('notification:global', {
                    message: 'Currency specific prices field is not defined in the payload',
                    type: 'error',
                })
                return
            }

            item.prices.currencySpecificPrices[currency].salesPrice = this.form.productPrice
            item.prices.currencySpecificPrices.original.salesPrice = this.form.productPrice * this.currencyConversionRates[currency]['EUR']
            item.productPrice = this.form.productPrice
            if (item.turnaround) {
                item.turnaround.price = this.form.productPrice

                if (item.turnaround.currencySpecificPrices) {
                    item.turnaround.currencySpecificPrices[currency].salesPrice = this.form.productPrice
                    item.turnaround.currencySpecificPrices.original.salesPrice = this.form.productPrice
                }
            }

            this.form.vat = this.form.productVatRate

            this.$emit('change', this.form)
            this.loading = true
        },
        resetForm () {
            this.$set(this, 'form', {})
            this.loading = false
            this.isOpen = false
            this.$emit('closed')
        },
    },
}
</script>
