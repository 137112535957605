<template>
        <form @submit.prevent="submitForm">
            <div class="row">
                <div class="col-sm-6">
                    <mercur-input type="number" v-model.number="form.productPrice" step="any" :class="getValidationClass($v, 'productPrice')">
                        Product Price
                        <template #note>
                            <span class="form-error" v-if="!$v.form.productPrice.minValue">Minimum value is 0</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-sm-6">
                    <mercur-input v-model.number="form.quantity" type="number">
                        Quantity
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.quantity.required">Quantity is required</span>
                            <span class="form-error" v-if="!$v.form.quantity.minValue">Quantity should be min: 1</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-md-12 col-lg-6">
                    <label for="dispatchDate">Expected dispatch date</label>
                    <mercur-input type="date" :class="getValidationClass($v, ['deliveryInfo', 'targetDispatchDate'])" v-model="form.deliveryInfo.targetDispatchDate">
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.deliveryInfo.targetDispatchDate.validDate">Target dispatch date is required</span>
                            <span class="form-error" v-if="!$v.form.deliveryInfo.targetDispatchDate.minValue">Target dispatch date should be today or after today</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-md-12 col-lg-6">
                    <label for="deliveryDate">Delivery date</label>
                    <mercur-input
                        type="date"
                        :class="getValidationClass($v, ['deliveryInfo', 'targetDeliveryDate'])"
                        v-model="form.deliveryInfo.targetDeliveryDate">
                        <template #note>
                            <span class="form-error" v-if="!$v.form.deliveryInfo.targetDeliveryDate.validDate">Delivery date is required</span>
                            <span class="form-error" v-if="!$v.form.deliveryInfo.targetDeliveryDate.minValue">Delivery date should be after dispatch date</span>
                        </template>
                    </mercur-input>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <mercur-button :disabled="isDisabled || isAddingProduct" class="btn btn-primary" type="submit">Add custom product</mercur-button>
                </div>
            </div>
        </form>
</template>

<script>
import moment from 'moment'
import FormMixin from '@/mixins/Form'
import { required, minValue } from 'vuelidate/lib/validators'
const validDate = (value) => moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value
export default {
    name: 'FunnelCustomPayloadBuilder',
    mixins: [ FormMixin ],
    data () {
        return {
            isDisabled: false,
            disabledDeliveryDates: date => {
                const day = date.getTime()
                const currentDay = new Date().getTime()
                return day <= currentDay
            },
            form: {
                deliveryInfo: {
                    targetDispatchDate: '',
                    targetDeliveryDate: '',
                },
                quantity: null,
            },
        }
    },
    props: {
        isAddingProduct: {
            default: false,
        },
    },
    validations: {
        form: {
            quantity: {
                required,
                minValue: minValue(1),
            },
            productPrice: {
                minValue: minValue(0),
            },
            deliveryInfo: {
                targetDispatchDate: {
                    required,
                    validDate,
                    minValue: function (value) {
                        return moment(value).isSame(moment(), 'day') || !moment(value).isBefore(moment(), 'day')
                    },
                },
                targetDeliveryDate: {
                    required,
                    validDate,
                    minValue: function (value) {
                        if (!this.$v.form.deliveryInfo.targetDeliveryDate.validDate) {
                            return false
                        }
                        if (!this.$v.form.deliveryInfo.targetDispatchDate.validDate) {
                            return false
                        }
                        return moment(value).isSameOrAfter(moment(this.form.deliveryInfo.targetDispatchDate))
                    },
                },
            },
        },
    },
    methods: {
        submitForm () {
            if (this.$v) {
                this.$v.$touch()
            }

            if (this.$v && this.$v.$invalid) {
                this.isDisabled = true
                return
            }
            const vat = this.selectedShop.configuration.vat ? this.selectedShop.configuration.vat.products : '0.23'

            const payload = {
                deliveryInfo: this.form.deliveryInfo,
                productPrice: this.form.productPrice ? this.form.productPrice : 0,
                prices: {
                    price: this.form.productPrice ? this.form.productPrice : 0,
                    priceVat: this.form.productPrice + (this.form.productPrice * vat),
                },
                productInfo: {
                    weight: 0,
                },
                quantity: this.form.quantity,
                producerSku: '',
                productPath: 'quote/customProduct',
            }
            this.$emit('completed', payload)
        },
    },
    watch: {
        form: {
            deep: true,
            handler () {
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    this.isDisabled = false
                } else {
                    this.isDisabled = true
                }
            },
        },
    },
}
</script>
