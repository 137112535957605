<template>
    <div class="u-rel c-attribute">
        <form @submit.prevent="selectSquareMeterAttribute">
            <div class="row">
                <div class="col-4">
                    <mercur-input  :disabled="isNotSelectable && !isCustomized" type="number" step="any" v-model="customVariables.width" required min="1">
                        width
                        <template #suffix>
                            <span>cm</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-4">
                    <mercur-input :disabled="isNotSelectable && !isCustomized" type="number" step="any" v-model="customVariables.height" required min="1">
                        height
                        <template #suffix>
                            <span>cm</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-4">
                    <div class="d-flex">
                        <mercur-input class="flex-grow-1" :value="surface" readonly>
                            {{ attributeName }}
                            <template #suffix>
                                <span>m<sup>2</sup></span>
                            </template>
                        </mercur-input>
                        <mercur-button type="submit" class="btn btn-primary btn-icon btn-icon-square"><i class="fas fa-save"></i></mercur-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import AttributeLogic from './AttributeLogic'

export default {
    name: 'SquareMeterProductAttribute',
    mixins: [AttributeLogic],
    data () {
        return {
            customVariables: {
                width: null,
                height: null,
            },
        }
    },
    computed: {
        surface () {
            return ((parseFloat(this.customVariables.height || 0) * parseFloat(this.customVariables.width || 0)) / 10000).toFixed(1)
        },
        attributeValues () {
            if (!this.attribute) {
                return null
            }
            return this.attribute.map(e => parseFloat(e.replace(/m2/g, ''))).sort((a, b) => a - b)
        },
    },
    methods: {
        selectSquareMeterAttribute () {
            const surface = parseFloat(this.surface)

            if (this.attributeValues.includes(surface)) {
                this.$emit('selectedSquareMeter', this.customVariables)
                this.selectAttribute(surface.toString())
                return
            }

            const closest = this.attributeValues.reduce((previous, current) => {
                return previous < surface ? current : previous
            })

            if (surface > closest) {
                this.$root.$emit('notification:global', {
                    message: `Maximum surface can be ${closest} square meters`,
                })
                return
            }

            this.$emit('selectedSquareMeter', this.customVariables)
            this.selectAttribute(closest.toString())
        },
    },
}
</script>

<style lang="scss" scoped>
    .loading-spinner {
        position: absolute;
        top: 25px;
        right: 5px;
        opacity: 0;
        background-color: white;
        &--is-loading {
            opacity: 1;
        }
    }

    .c-attribute__surface-display {
        max-width: calc(100% - 109px);
    }

</style>
