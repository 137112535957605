<script>
import countries from 'country-region-data'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    name: 'HelperMethods',
    data () {
        return {
            countries,
        }
    },
    computed: {
        ...mapState('shop', ['applicationVatProfiles']),
        ...mapState('order', ['order']),
        ...mapGetters('shop', ['workingCountriesList']),
    },
    methods: {
        ...mapActions('shop', ['fetchApplicationVatProfiles']),
        verifyShippingAddress (address, checkCurrency = false) {
            const addressCountryCode = this.countries.find(e => e.countryName === address.country).countryShortCode
            if (!addressCountryCode) {
                this.$root.$emit('notification:global', {
                    message: 'Not a valid country',
                    type: 'error',
                })
                return false
            }

            if (this.applicationVatProfiles && !Object.keys(this.applicationVatProfiles).includes(addressCountryCode)) {
                this.$root.$emit('notification:global', {
                    message: 'Selected address country is not one of shop\'s working countries',
                    type: 'error',
                })
                return false
            }

            if (checkCurrency && this.workingCountriesList[addressCountryCode].currency !== this.order.currency) {
                this.$root.$emit('notification:global', {
                    message: 'Address currency is not matching order currency',
                    type: 'error',
                })
                return false
            }

            return true
        },
        checkShipsTo (orderLine) {
            if (orderLine.vatProfile) {
                return true
            }

            if (!this.applicationVatProfiles) {
                this.$root.$emit('notification:global', {
                    message: `Vat profiles for this shop are missing. We are trying to get them for you.`,
                    type: 'error',
                })
                this.fetchApplicationVatProfiles()
                return false
            }

            let isValid = true
            const address = Object.assign(orderLine.deliveryInfo.address, {})
            address.countryCode = this.countries.find((e) => e.countryName === address.country).countryShortCode
            if (!Object.keys(this.applicationVatProfiles).includes(address.countryCode)) {
                this.$root.$emit('notification:global', {
                    message: `Shop does not ship to ${address.country}`,
                    type: 'error',
                })
                isValid = false
                return false
            } else {
                orderLine.vatProfile = this.applicationVatProfiles[address.countryCode]
            }

            return isValid
        },
        checkIfMatchesOrderCurrency (address) {
            const addressCountryCode = this.countries.find((e) => e.countryName === address.country).countryShortCode
            return this.workingCountriesList[addressCountryCode].currency === this.workingCountriesList[this.orderCountry].currency
        },
    },
}
</script>
