export default {
    data () {
        return {
            value: null,
            customValue: '',
            isCustomValue: false,
            valueWasSet: false,
        }
    },
    props: {
        attribute: {},
        attributeName: null,
        index: null,
        selectedIndex: {
            default: null,
        },
        loading: {
            default: false,
        },
        isTurnaround: {
            default: false,
        },
        presetValue: {
            default: null,
        },
        isCustomizable: {
            default: false,
        },
        isCustomized: {
            default: false,
        },
        isLegacy: {
            default: false,
        },
        disabled: {
            default: false,
        },
    },
    computed: {
        isNotSelectable () {
            if (this.loading) {
                return true
            }
            return this.index > this.selectedIndex
        },
    },
    watch: {
        selectedIndex () {
            if (this.index >= this.selectedIndex && this.isLegacy && !this.isCustomized) {
                this.isCustomValue = false
                this.customValue = ''
                if (this.$refs.attributeSelect) {
                    this.$refs.attributeSelect._data.localValue = undefined
                }
            }
        },
        presetValue () {
            this.$set(this, 'value', JSON.parse(JSON.stringify(this.presetValue)))
            if (this.$refs.attributeSelect) {
                this.$refs.attributeSelect._data.localValue = JSON.parse(JSON.stringify(this.presetValue))
            }
            this.valueWasSet = true
        },
    },
    methods: {
        selectAttribute ($event) {
            if (!$event) {
                return
            }

            if (this.presetValue && $event && this.presetValue !== $event) {
                this.valueWasSet = false
            }

            if (this.valueWasSet) {
                this.valueWasSet = false
                return
            }

            this.$emit('selected', {
                index: this.index,
                key: this.attributeName,
                value: $event,
            })
        },
        setCustomValue ($event) {
            $event.target.blur()
            this.$emit('customAttributeWasSet', {
                index: this.index,
                key: this.attributeName,
                value: this.customValue,
                isCustom: true,
            })
        },
    },
    mounted () {
        if (!this.presetValue) {
            return
        }
        this.$set(this, 'value', JSON.parse(JSON.stringify(this.presetValue)))
        this.$refs.attributeSelect._data.localValue = JSON.parse(JSON.stringify(this.presetValue))
    },
}
