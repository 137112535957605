<template>
    <div class="o-funnel">
        <div v-if="isCustomizable" class="flex-grow-1 mb-3">
            Type of product:
            <br />
            <span class="mr-2">
                <input v-model="productType" value="normal" @change="setProductType" type="radio" id="predefined">
                <label for="predefined">Predefined</label>
            </span>
            <span>
                <input v-model="productType" value="custom" @change="setProductType" type="radio" id="custom">
                <label for="custom">Custom</label>
            </span>
        </div>
        <div class="d-flex" v-if="items.length">
            <pretty-select
                v-if="!isCustomProductName"
                data-e2e="listOfProductsForFunnel"
                label="label"
                placeholder="Products"
                :options="items"
                v-model="selectedProduct"
                :reduce="option => option.label"
                class="flex-grow-1 mb-3"
            />
            <mercur-input class="flex-grow-1" v-else v-model="customProductName" @keyup="setCustomProductName">
                Set custom product name
            </mercur-input>
        </div>

        <div v-if="isPresetting" data-e2e="loadingProductOverlay" class="preset-overlay">
            <p>Presetting your product...</p>
            <mercur-spinner size="4"></mercur-spinner>
        </div>

        <div id="attributes" v-if="product">
            <template v-if="isCustomProductName && Array.isArray(product.selectedAttributes) && product.selectedAttributes.length">
                <template v-if="isFreeFillAttributes">
                    <mercur-textarea v-model="product.selectedAttributes[0].attributeOption">
                        Attributes:
                    </mercur-textarea>
                </template>
                <div v-else class="o-funnel__custom-field">
                    <div class="d-flex align-items-center" v-for="(item, index) in product.selectedAttributes.length" :key="index">
                        <mercur-input class="flex-grow-1" v-model="product.selectedAttributes[index].attributeName" :class="!product.selectedAttributes[index].attributeName ? 'form-invalid' : ''">
                            Custom attribute name
                        </mercur-input>
                        <mercur-input class="flex-grow-1" v-model="product.selectedAttributes[index].attributeOption" :class="!product.selectedAttributes[index].attributeOption ? 'form-invalid' : ''">
                            Custom value
                        </mercur-input>
                        <mercur-button class="btn btn-icon" @click.native="removeAttribute(index)"><i class="fas fa-trash"></i></mercur-button>
                    </div>
                    <mercur-button class="btn btn-icon text-white btn-yellow o-funnel__custom-field-add" @click.native="addEmpty"><i class="fas fa-plus"></i></mercur-button>
                </div>
            </template>

            <template v-if="!isCustomProductName">
                <component
                    :is="SQUARE_METER_ATTRIBUTES.includes(attribute) ? 'SquareMeterProductAttribute' : 'ProductAttribute'"
                    v-for="(attribute, index) in product.attributeMap"
                    :key="index"
                    :attribute="product.remainingAttributes[attribute]"
                    :attributeName="attribute"
                    :loading="loading"
                    data-e2e="attributeProductAttribute"
                    :index="index + 1"
                    :selectedIndex="selectedIndex"
                    :presetValue="presetProduct ? presetProduct.attributes[attribute] : null || product.selectedAttributes[attribute] ? product.selectedAttributes[attribute] : null"
                    @selected="setAttribute"
                    @selectedSquareMeter="setCustomVariables"
                    @customAttributeWasSet="setCustomAttribute"
                    :isCustomizable="isCustomizable"
                    :isCustomized="!!customAttributes.length"
                />
            </template>

            <product-attribute
                v-if="product.attributeMap && !isCustomProductName"
                :isCustomizable="isCustomizable"
                :isCustomized="!!customAttributes.length"
                attributeName="printrun"
                data-e2e="printrunProductAttribute"
                :presetValue="presetProduct ? presetProduct.attributes['printrun'] : null || product.selectedQuantity ? product.selectedQuantity : null"
                :attribute="product.availableQuantities"
                :loading="loading" :index="product.attributeMap.length + 1"
                :selectedIndex="selectedIndex"
                @selected="getTurnarounds"
                @customAttributeWasSet="setCustomAttribute"
            />

            <product-attribute
                v-if="product.availableQuantities && !customAttributes.length"
                :isCustomizable="isCustomizable"
                attributeName="turnaround"
                data-e2e="turnaroundProductAttribute"
                :presetValue="turnaroundPreset || product.selectedAttributes['turnaround'] ? product.selectedAttributes['turnaround'] : null"
                :attribute="product.turnarounds"
                :loading="loading"
                :index="product.attributeMap.length + 2"
                :selectedIndex="selectedIndex"
                @selected="setVariation"
                :isTurnaround="true"
            />

            <mercur-spinner data-e2e="loadingSpinner" v-if="!Object.keys(product).length || !items.length && loading"></mercur-spinner>

            <div v-if="customAttributes.length || isCustomProductName">
                <funnel-custom-payload-builder @completed="addCustomProduct" :isAddingProduct.sync="isAddingProduct"/>
            </div>
            <div id="variationData" v-if="!isCustomProductName">
                <template v-if="Object.keys(variation).length">
                    <h4>
                        Price:
                        <currency :value="advancedPrices['salesPrice']" :type="shopCurrency"/>
                        - shipping:
                        <currency :value="variation.shippingPrice" :type="shopCurrency"/>
                        <mercur-button class="btn btn-icon btn-icon-mini" v-if="isPriceEditable" @click.native="triggerEditVariationPrice"><i class="fas fa-edit"></i></mercur-button>
                    </h4>
                </template>
                <mercur-button class="btn btn-primary" data-e2e="addProductButton" @click.native="addProduct" :disabled="isAddingProduct || !Object.keys(variation).length">Add Product</mercur-button>
            </div>
            <div v-if="isPriceEditable">
                <edit-dialog :fields="draft.variation" ref="editDialog" @change="modifyVariation" :isEditVats="false">Modify prices</edit-dialog>
            </div>

        </div>

    </div>
</template>

<script>
import CONFIG from '@root/config'
import ProductAttribute from '@/components/elements/product/Attribute'
import SquareMeterProductAttribute from '@/components/elements/product/SquareMeterAttribute'
import PrettySelect from '@/components/utils/PrettySelect'
import FunnelCustomPayloadBuilder from '@/components/elements/FunnelCustomPayloadBuilder'
import { mapGetters } from 'vuex'
import EditDialog from '@/components/elements/EditDialog'
import { transformProductAttributes, transformProductAttributesToObject } from '@/utils/helpers'
export default {
    name: 'Funnel',
    components: { ProductAttribute, PrettySelect, FunnelCustomPayloadBuilder, SquareMeterProductAttribute, EditDialog },
    props: {
        presetProduct: {
            default: null,
        },
        isCustomizable: {
            default: false,
        },
        isPriceEditable: {
            default: false,
        },
        isShippingOverwritten: {
            default: false,
        },
        country: {
            default: null,
        },
        isFreeFillAttributes: {
            default: false,
        },
    },
    data () {
        return {
            productType: 'normal',
            product: {
                selectedAttributes: {},
            },
            productMapping: null,
            variation: {},
            selectedIndex: null,
            loading: false,
            isAddingProduct: false,
            selectedProduct: '',
            updatedPrice: null,
            isPresetting: false,
            turnaroundPreset: null,
            customAttributes: [],
            turnaroundMatrices: null,
            additionalProductInfo: {},
            SQUARE_METER_ATTRIBUTES: ['surface'],
            triedFetchingQuantites: false,
            isCustomProductName: false,
            customProductName: '',
            customQuoteProduct: {},
            draft: {},
            items: [],
            hasCatalogProducts: false,
        }
    },
    watch: {
        selectedProduct (value) {
            if (this.customProductName.length || !value || !value.length) {
                return
            }
            this.productMapping = this.items.find((item) => item.label === value)
            this.$set(this, 'product', {})
            this.selectedIndex = 1
            this.customAttributes = []
            this.selectProduct()
        },
        selectedIndex (value) {
            if (!this.product.attributeMap) {
                return
            }

            if (value < this.product.attributeMap.length) {
                this.triedFetchingQuantites = false
            }
        },
    },
    computed: {
        ...mapGetters('shop', ['calculatedCurrency']),
        advancedPrices () {
            if (!this.variation && this.variation.prices) {
                return
            }
            // TODO: fix this
            return this.variation.prices.currencySpecificPrices[this.shopCurrency]
        },
        shopCurrency () {
            return this.$store.getters['shop/calculatedCurrency'](this.country)
        },
    },
    methods: {
        getProductsForFunnel () {
            const url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.LIST_CATALOG_PRODUCT_VARIANTS
            const payload = {
                country: this.country,
            }
            this.post(url, payload).then(({ data }) => {
                this.items = data
                this.hasCatalogProducts = false
                if (this.items.length) {
                    this.hasCatalogProducts = true
                    this.presetProductAction()
                }
            }).finally(() => {
                if (!this.items.length) {
                    this.getProductsFromAgreement()
                }
            })
        },
        getProductsFromAgreement () {
            const url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.LIST_PRODUCTS
            this.get(url).then(({ data }) => {
                this.items = data
                this.items.forEach((item, index) => {
                    item.key = index
                    if (item.label.includes('#')) {
                        this.items[index].label = item.label.split('#')[1]
                    }
                })
                this.presetProductAction()
            })
        },
        filterRemainingAttributesWithCatalogAttributes () {
            const availableAttributes = this.productMapping.productAttributes.selectedAttributes
            for (let key in this.product.remainingAttributes) {
                const optionsArray = availableAttributes[key].map(e => e.option)
                this.product.remainingAttributes[key] = this.product.remainingAttributes[key].filter(
                    option => optionsArray.includes(option)
                )
            }
        },
        presetProductAction () {
            if (!this.isPresetting) {
                return
            }

            this.productMapping = this.items.find((item) => item.productHash === this.presetProduct.productHash)
            if (!this.productMapping) {
                this.$root.$emit('notification:global', {
                    message: 'Product not found for this shop hash',
                    type: 'error',
                })
                return
            }
            this.$set(this, 'product', {})
            this.selectProduct().then(() => {
                this.$set(this.product, 'selectedAttributes', this.presetProduct.attributes)
                this.selectedIndex = this.product.attributeMap.length + 2

                delete this.product.selectedAttributes.printrun
                delete this.product.selectedAttributes.turnaround
                this.selectProduct(true).then(() => {
                    this.getTurnaroundMatrices().then(() => {
                        this.getTurnarounds({
                            value: JSON.parse(JSON.stringify(this.presetProduct.quantity)),
                            index: this.product.attributeMap.length + 2,
                        }).then(() => {
                            this.isPresetting = false
                        })
                    })
                })
            })
        },
        async selectProduct (invalidateRecursiveCall = false) {
            if (!this.productMapping) {
                return
            }
            this.variation = {}
            let payload = {
                selectedAttributes: {},
            }

            if (this.product.selectedAttributes) {
                payload = {
                    selectedAttributes: JSON.parse(JSON.stringify(this.product.selectedAttributes)),
                }
            }

            const url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_ATTRIBUTES_FOR_FUNNEL.replace('{shopHash}', this.productMapping.shopHash).replace('{productHash}', this.productMapping.productHash)
            // Fetching quantities and turnaround for advanced products
            if (this.product.attributeMap && Object.keys(this.product.selectedAttributes).length >= this.product.attributeMap.length) {
                payload.getQuantities = true
                this.product.preselectedAttributes.forEach((item) => {
                    payload.selectedAttributes[item.attributeName] = item.attributeOption
                })

                this.getTurnaroundMatrices()
            }

            if (this.country) {
                payload.countryCode = this.country
            }

            this.addJob(url)
            this.loading = true
            await this.$api.post(url, payload).then(({ data }) => {
                // First time fetching a product
                if (!Object.keys(this.product).length) {
                    this.$set(this, 'product', data.data)

                    if (Array.isArray(this.product.selectedAttributes)) {
                        this.$set(this.product, 'selectedAttributes', {})
                    }

                    this.$set(this.product, 'attributeMap', Object.keys(data.data.remainingAttributes).sort())
                }

                if (!data.data.availableQuantities.length) {
                    this.product.preselectedAttributes = data.data.preselectedAttributes
                }

                if (Array.isArray(data.data.selectedAttributes)) {
                    data.data.selectedAttributes.forEach((item) => {
                        if (!Object.keys(this.product.selectedAttributes).includes(item.attributeName) && this.product.attributeMap.includes(item.attributeName)) {
                            this.$set(this.product.selectedAttributes, item.attributeName, item.attributeOption)
                        }
                    })
                }

                for (let i = 0; i < this.product.attributeMap.length; i++) {
                    if (!Object.keys(this.product.selectedAttributes).includes(this.product.attributeMap[i])) {
                        break
                    }
                    this.selectedIndex = i + 2
                }

                if (data.data.availableQuantities && data.data.availableQuantities.length) {
                    this.$set(this.product, 'availableQuantities', data.data.availableQuantities)
                }

                if (Array.isArray(data.data.remainingAttributes) && !data.data.remainingAttributes.length && !this.triedFetchingQuantites && !invalidateRecursiveCall) {
                    if (!this.product.availableQuantities || !this.product.availableQuantities.length) {
                        this.triedFetchingQuantites = true
                        this.selectProduct(true).catch((err) => {
                            console.log(err)
                        })
                    }
                }

                if (typeof data.data.remainingAttributes === 'object' && Object.keys(data.data.remainingAttributes).length) {
                    this.$set(this.product, 'logisticsDates', data.data.logisticsDates)
                    this.product.remainingAttributes = { ...this.product.remainingAttributes, ...data.data.remainingAttributes }
                    if (this.hasCatalogProducts) {
                        this.filterRemainingAttributesWithCatalogAttributes()
                    }
                    // Part to filter options for attributes that only have one option
                    const preselectedAttributes = data.data.preselectedAttributes.map(e => e.attributeName)
                    for (let key in this.product.remainingAttributes) {
                        if (preselectedAttributes.includes(key)) {
                            this.product.remainingAttributes[key] = [data.data.preselectedAttributes[preselectedAttributes.findIndex(e => e === key)].attributeOption]
                        }
                    }
                } else {
                    this.$set(this.product, 'skus', data.data.skus)
                }
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    this.$root.$emit('notification:global', {
                        message: 'Error fetching attribute options',
                    })
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
                this.loading = false
            })
        },
        setAttribute (item) {
            this.product.selectedAttributes[item.key] = item.value
            // Part to reset values that come after selected attribute
            if (item.index < this.selectedIndex && !this.customAttributes.length) {
                const index = this.product.attributeMap.indexOf(item.key) + 1
                const arr = this.product.attributeMap.slice(index)

                arr.forEach((item) => {
                    this.$delete(this.product.selectedAttributes, [item])
                })

                this.$delete(this.product.selectedAttributes, 'printrun')
                this.$delete(this.product.selectedAttributes, 'turnaround')
                this.$set(this.product, 'selectedQuantity', null)
                this.$delete(this.product, 'turnaroundHash')
                this.$delete(this.product, 'turnarounds')
                this.product.availableQuantities = []
                this.product.skus = []
                this.$set(this, 'turnaroundPreset', null)

                if (this.customAttributes.length) {
                    this.customAttributes = this.customAttributes.filter((e) => {
                        return Object.keys(this.product.selectedAttributes).includes(e)
                    })
                }
            }

            if (!item.isCustom && this.customAttributes.length) {
                this.customAttributes = this.customAttributes.filter((e) => {
                    return e !== item.key
                })
            }

            if (item.isCustom || this.customAttributes.length) {
                this.selectedIndex = item.index + 1
                return
            }

            this.triedFetchingQuantites = false
            if (this.product.preselectedAttributes && this.product.preselectedAttributes.map(e => e.attributeName).includes(item.key)) {
                return
            }

            this.selectProduct()
        },
        async getTurnaroundMatrices () {
            if (this.hasCatalogProducts) {
                return
            }
            const turnaroundUrl = CONFIG.API.ROUTES.TURNAROUND_STRATEGY.GET_MATRIX_FOR_PRODUCT
                .replace('{shopHash}', this.productMapping.shopHash)
                .replace('{productHash}', this.productMapping.productHash)
            await this.get(turnaroundUrl, null, 'Error fetching turnaround matrix').then(({ data }) => {
                this.turnaroundMatrices = data
            })
        },
        setCustomAttribute (item) {
            this.variation = {}
            this.customAttributes.push(item.key)
            this.setAttribute(item)
        },
        setCustomVariables (payload) {
            this.additionalProductInfo.customVariables = payload
        },
        async getTurnarounds (item) {
            let url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_TURNAROUNDS
                .replace('{shopHash}', this.product.shopHash)
                .replace('{productHash}', this.product.productHash)

            this.$set(this.product, 'selectedQuantity', item.value)
            this.$set(this.product.selectedAttributes, 'printrun', item.value)

            this.$set(this.product.selectedAttributes, 'turnaround', null)
            this.$set(this.product, 'turnarounds', [])
            this.$set(this, 'variation', {})

            const payload = {
                sku: this.product.skus[0] ? this.product.skus[0].toUpperCase() : this.presetProduct.sku,
                quantity: item.value,
                countryCode: this.country ? this.country : Object.keys(this.selectedShop.configuration.workingCountries)[0],
            }

            if (this.hasCatalogProducts) {
                url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_PRODUCT_TURNAROUNDS
                    .replace('{shopHash}', this.product.shopHash)
                    .replace('{productHash}', this.product.productHash)
            } else {
                payload.turnaroundMatrices = this.turnaroundMatrices
            }

            this.addJob(url)
            this.loading = true
            await this.$api.post(url, payload).then(({ data }) => {
                this.selectedIndex = item.index + 1
                if ((Array.isArray(data.data) && !data.data.length) && (!Array.isArray(data.data) && !Object.keys(data.data).length)) {
                    this.$root.$emit('notification:global', {
                        message: 'There are no turnarounds for this combination',
                        type: 'error',
                    })
                }

                this.$set(this.product, 'turnarounds', data.data)
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: 'Error getting turnarounds...',
                    type: 'error',
                })
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
                this.loading = false
            })
        },
        async getVariation () {
            const url = CONFIG.API.ROUTES.PRODUCTS.VARIATION
            this.addJob(url)
            this.$set(this, 'variation', {})

            this.loading = true
            await this.$api.post(url, {
                selectedAttributes: { ...this.product.selectedAttributes, ...this.product.preselectedAttributes },
                productName: this.productMapping.label,
                platformReference: this.productMapping.platformReference,
            }).then(({ data }) => {
                this.variation = data.data
                this.variation.deliveryInfo = {
                    targetDeliveryDate: this.product.logisticsDates[this.variation.attributes.turnaround].deliveryDate,
                    targetDispatchDate: this.product.logisticsDates[this.variation.attributes.turnaround].dispatchDate,
                }
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
                this.loading = false
            })
        },
        setVariation (item) {
            this.loading = true
            this.$set(this.product.selectedAttributes, 'turnaround', item.value)
            this.$set(this.product, 'turnaroundHash', this.product.turnarounds[item.value].calculationId)

            this.$set(this, 'variation', {})

            const preselectedAttributes = transformProductAttributesToObject(JSON.parse(JSON.stringify(this.product.preselectedAttributes)))
            const initialVariation = {
                attributes: { ...this.product.selectedAttributes, ...preselectedAttributes },
                selectedQuantity: this.product.selectedQuantity,
                turnaround: Object.values(this.product.turnarounds).find((e) => e.turnaround === item.value),
                turnaroundHash: this.product.turnarounds[item.value].calculationId,
                additionalProductInfo: {
                    ...this.additionalProductInfo,
                    cutOffDate: this.product.turnarounds[item.value].cutOffDate,
                },
                sku: this.product.skus[0],
                shopHash: this.product.shopHash,
                productHash: this.product.productHash,
                productName: this.selectedProduct,
            }

            const url = CONFIG.API.ROUTES.ORDERS.GET_VARIATION

            const payload = {
                product: initialVariation,
                productPrice: initialVariation.turnaround.price,
                shippingPrice: initialVariation.turnaround.shippingPrice, // business rule to display that shipping is free, it is included in price
                currency: this.shopCurrency,
            }

            this.post(url, payload).then((data) => {
                this.$set(this, 'variation', data.data)
                if (this.isShippingOverwritten) {
                    this.variation.shippingPrice = 0
                }
                this.loading = false
            }).catch((e) => {
                this.$root.$emit('notification:global', {
                    message: e,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },
        clearFunnel () {
            this.variation = {}
            this.selectedIndex = 1
            this.product = {
                selectedAttributes: {},
            }
            this.customAttributes = []
            this.isAddingProduct = false
            this.$set(this, 'selectedProduct', '')
            this.isCustomProductName = false
            this.$set(this, 'productType', 'normal')
            this.customProductName = ''
        },
        addProduct () {
            this.isAddingProduct = true
            this.variation.additionalProductInfo = {
                ...this.additionalProductInfo,
                dataPlatformReference: this.selectedProduct,
                cutOffTime: this.variation.producer ? this.variation.producer.cutOffTime : '',
            }
            let payload = JSON.parse(JSON.stringify(this.variation))
            if (typeof payload.attributes === 'object') {
                payload.attributes = transformProductAttributes(payload.attributes)
            }

            if (this.hasCatalogProducts && this.productMapping.catalogProductId) {
                const url = CONFIG.API.ROUTES.VAT_CONFIG.VAT_PROFILES.FOR_PRODUCT_VARIANT.replace('{productVariantId}', this.productMapping.catalogProductId)
                this.get(url).then(({ data }) => {
                    if (data[this.country]) {
                        payload.vatProfile = data[this.country]
                    }
                    this.$emit('productAdded', payload)
                })
            } else {
                this.$emit('productAdded', payload)
            }
        },
        addCustomProduct (passedData) {
            if (Object.keys(this.product.selectedAttributes).length < this.product.attributeMap.length - 1) {
                this.$root.$emit('notification:global', {
                    message: 'Select remaining attributes',
                })
                return
            }
            if (this.isCustomProductName) {
                this.product.selectedAttributes.forEach((attribute) => {
                    this.product.attributeMap.push(attribute.attributeName)
                })
            }
            this.isAddingProduct = true
            const map = this.product.attributeMap
            let sku = ''
            if (map.includes('turnaround')) {
                map.pop('turnaround')
            }
            if (!this.isCustomProductName) {
                map.forEach((key, index) => {
                    if (index === map.length - 1) {
                        sku += this.product.selectedAttributes[key].toString().replace(/ /g, '_')
                    } else {
                        sku += this.product.selectedAttributes[key].toString().replace(/ /g, '_') + '-'
                    }
                })
            }

            let attributes = []
            if (typeof this.product.selectedAttributes === 'object' && !Array.isArray(this.product.selectedAttributes)) {
                attributes = transformProductAttributes(this.product.selectedAttributes)
            } else {
                attributes = this.product.selectedAttributes
            }

            const conversionRatesUrl = CONFIG.API.ROUTES.CARRIERS.GET_CURRENCY_CONVERSION_RATES
            this.$api.get(conversionRatesUrl).then(({ data }) => {
                const conversionRate = data.data[this.shopCurrency]['EUR'] || 1
                passedData.prices = {
                    currencyCode: this.shopCurrency,
                    currencySpecificPrices: {
                        original: {
                            currencyCode: 'EUR',
                            conversionRate: conversionRate,
                            salesPrice: passedData.prices.price * conversionRate,
                            serviceLevelCost: 0,
                        },
                        [this.shopCurrency]: {
                            currencyCode: this.shopCurrency,
                            conversionRate: data.data['EUR'][this.shopCurrency] || 1,
                            salesPrice: passedData.prices.price,
                            serviceLevelCost: 0,
                        },
                    },
                    productCost: 0,
                    shippingCost: 0,
                    totalCost: 0,
                }

                passedData.turnaround = {
                    price: passedData.productPrice,
                    shippingPrice: 0,
                }

                if (this.isCustomProductName) {
                    this.$set(passedData, 'shopHash', this.items.find(product => product.shopHash).shopHash)
                }

                const payload = {
                    ...passedData,
                    sku: sku.toUpperCase(),
                    quantity: parseInt(this.product.selectedAttributes.printrun || passedData.quantity),
                    attributes: attributes,
                    productName: this.selectedProduct,
                    productionDays: 0,
                    additionalProductInfo: {
                        ...this.additionalProductInfo,
                        dataPlatformReference: this.selectedProduct,
                    },
                }

                this.$emit('productAdded', payload)
            })
        },
        setProductType (event) {
            const productType = event.target.value
            this.isCustomProductName = productType === 'custom'
            this.selectedProduct = ''
            this.customProductName = ''
            this.customAttributes = []
            this.product = {
                selectedAttributes: {},
            }
        },
        setCustomProductName () {
            this.selectedProduct = this.customProductName
            this.customAttributes.push('productName')
            if (this.isCustomProductName) {
                this.product = {
                    attributeMap: [],
                    selectedAttributes: [
                        {
                            attributeName: this.isFreeFillAttributes ? 'description' : '',
                            attributeOption: '',
                        },
                    ],
                }
                this.selectedIndex = 1

                return
            }

            this.selectedIndex = null

            this.product = {
                selectedAttributes: {},
            }
        },
        addEmpty () {
            this.product.selectedAttributes.push({
                attributeName: '',
                attributeOption: '',
            })
        },
        removeAttribute (index) {
            this.product.selectedAttributes.splice(index, 1)
        },
        triggerEditVariationPrice () {
            this.$set(this.draft, 'variation', {
                ...this.variation,
                currency: this.variation.prices.currencyCode,
            })
        },
        modifyVariation (data) {
            this.variation = data
            this.$refs.editDialog.resetForm()
            this.$set(this, 'draft', {})
        },
    },
    mounted () {
        if (this.presetProduct) {
            this.isPresetting = true
            this.$set(this, 'selectedProduct', this.presetProduct.productName.toLowerCase())
        }
    },
    created () {
        // @TODO: Uncomment this to get from PCM and remove line 775
        // this.getProductsForFunnel()

        this.getProductsFromAgreement()
    },
}
</script>

<style lang="scss" scoped>
    .loading-spinner {
        margin-top: 10px;
        opacity: 0;
        &--is-loading {
            opacity: 1;
        }
    }

    .preset-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255,255,255,.6);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        flex-direction: column;
    }

    .o-funnel {
        &__custom-product {
            margin-top: -12px;
        }

        &__quote-button {
            margin-left: 10px;
        }

        &__btn-price-edit {
            cursor: pointer;
            font-size: 20px !important;
            margin-left: 5px;
        }

        &__custom-field {
            background: #f2f2f2;
            padding: 0 10px;
            border-radius: 2px;
            margin-bottom: 10px;
            position: relative;

            &-add {
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;
                margin: auto;
                height: 20px !important;
                width: 20px !important;
                min-width: 20px !important;
                transition: all ease .2s;

                &:before {
                    display: none;
                }

                &:hover {
                    transform: scale(1.5);
                }
            }

            &-save {
                margin-left: 10px;
            }
        }
    }
</style>
