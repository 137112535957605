<template>
    <mercur-dialog :is-open.sync="isOpen" @close="resetForm">
        <h3 class="font-weight-normal"><slot></slot></h3>
        <template v-if="Object.keys(form).length">
            <mercur-input type="number" step="any" v-model.number="form.productPrice" :class="{ 'form-invalid': $v.form.productPrice.$invalid }">
                Product price - <currency :value="form.productPrice" :type="this.form.currency"></currency>
                <template #note>
                    <span class="form-error" v-if="!$v.form.productPrice.required">Field is required</span>
                </template>
            </mercur-input>
            <template v-if="isEditVats">
                <mercur-input type="number" step="0.01" v-model.number="form.productVatRate" :class="{ 'form-invalid': $v.form.productVatRate.$invalid }">
                    Product vat rate
                    <template #note>
                        <span class="form-error" v-if="!$v.form.productVatRate.between">Must be between 0 and 1</span>
                        <span class="form-error" v-if="!$v.form.productVatRate.required">Field is required</span>
                    </template>
                </mercur-input>
                <mercur-input type="number" step="0.01" v-model.number="form.shippingVatRate" :class="{ 'form-invalid': $v.form.shippingVatRate.$invalid }">
                    Shipping vat rate
                    <template #note>
                        <span class="form-error" v-if="!$v.form.shippingVatRate.between">Must be between 0 and 1</span>
                        <span class="form-error" v-if="!$v.form.shippingVatRate.required">Field is required</span>
                    </template>
                </mercur-input>
            </template>
        </template>
        <template #footer>
            <mercur-button class="btn" @click.native="isOpen = false" :disabled="loading">Close</mercur-button>
            <mercur-button class="btn btn-primary" @click.native.prevent="save" :disabled="loading">Save</mercur-button>
        </template>
    </mercur-dialog>
</template>

<script>
import { required, between } from 'vuelidate/lib/validators'
import FormMixin from '@/mixins/Form'
import DialogLogicMixin from '@/mixins/DialogLogic'

export default {
    name: 'EditDialog',
    mixins: [ FormMixin, DialogLogicMixin ],
    validations () {
        const form = {
            productPrice: { required },
        }

        if (this.isEditVats) {
            form.productVatRate = { between: between(0, 1), required }
            form.shippingVatRate = { between: between(0, 1), required }
        }

        return {
            form,
        }
    },
}
</script>
